import React from 'react';

const ThankYouPage = () => (
  <div>
    <h1>Contact</h1>
    <p>Thank you for your submission!</p>
  </div>
);

export default ThankYouPage;
